import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { Component, ReactNode } from 'react';
import './App.css';
import { getStatistics, getPeriods, getStatisticToday } from './services/services';

const beforeStartDownAnnounceDate = moment().format('YYYY-MM-DD 16:20:01');
const afterStartDownAnnounceDate = moment().format('YYYY-MM-DD 16:21:59');
const startDownAnnounceDate = moment().format('YYYY-MM-DD 16:22:00');
const endDownAnnouneDate = moment().format('YYYY-MM-DD 16:24:59');
const downAnnounceDate = moment().format('YYYY-MM-DD 16:25:00');

const beforeStartUpAnnounceDate = moment().format('YYYY-MM-DD 16:25:01');
const afterStartUpAnnounceDate = moment().format('YYYY-MM-DD 16:26:59');
const startUpAnnounceDate = moment().format('YYYY-MM-DD 16:27:00');
const endUpAnnouneDate = moment().format('YYYY-MM-DD 16:29:59');
const upAnnounceDate = moment().format('YYYY-MM-DD 16:30:00');


const randomNumber = () => {
  const rand = Math.floor(Math.random() * 10);
  return rand + '.png';
}

const getLoading = () => {
  return 'loading.gif';
}

class App extends Component {
  _isMounted = false;
  timer: any = null;
  downTimer: any = null;
  upTimer: any = null;

  state = {
    beforeStartDown: false,
    beforeStartUp: false,
    todayDate: moment().format('YYYY-MM-DD HH:mm:ss'),
    announceStartDate: moment().format('YYYY-MM-DD 16:20:00'),
    announceEndDate: moment().format('YYYY-MM-DD 16:30:00'),
    isMobile: false,
    lImage: 60,
    hImage: 90,
    onePrizes: {
      one: getLoading(),
      two: getLoading(),
      three: getLoading(),
      four: getLoading(),
      five: getLoading(),
      six: getLoading(),
    },
    twoPrizes: {
      one: getLoading(),
      two: getLoading(),
      three: getLoading(),
      four: getLoading(),
      five: getLoading(),
      six: getLoading(),
    },
    oldAnnounce: false,
    newAnnounce: false,
    downAnnounce: false,
    oldUpAnnounce: false,
    downRelease: false,
    upAnnounce: false,
    statistics: []
  }

  componentWillUnmount(): void {
    this._isMounted = false;
    clearInterval(this.downTimer);
    clearInterval(this.upTimer);
    window.removeEventListener("resize", this.resizeWindow);
  }

  componentDidMount(): void {
    this._isMounted = true;
    this.getAllStatistics();
    window.addEventListener("resize", this.resizeWindow);
    this.resizeWindow();

    this.setTimer();
  }

  getAllStatistics = async () => {
    const response: AxiosResponse = await getStatistics();
    this.setState({
      statistics: response.data
    })
  }

  setTimer = () => {
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.setState({ todayDate: moment().format('YYYY-MM-DD HH:mm:ss') });
        this.announce();
      }, 1000);
    }
  }

  announce = () => {
    const { announceStartDate, announceEndDate, todayDate } = this.state;

    if (moment(todayDate).toDate().getTime() < moment(announceStartDate).toDate().getTime()) {
      this.beforeAnnonce();
    } else if (
      moment(todayDate).toDate().getTime() >= moment(announceStartDate).toDate().getTime()
      && moment(todayDate).toDate().getTime() <= moment(announceEndDate).toDate().getTime()
    ) {
      this.setData();
    } else {
      this.afterAnnounce();
    }
  }

  beforeAnnonce = async () => {
    const { todayDate, oldAnnounce } = this.state;
    if (!oldAnnounce) {
      const dateNow = moment(todayDate).add(-1, 'd').format("YYYY-MM-DD 16:30:00");
      const period = await getPeriods(dateNow, 'both', '2');
      const up = period.data.up6;
      const down = period.data.down6;

      this.setState({
        onePrizes: {
          one: up[0] + '.png',
          two: up[1] + '.png',
          three: up[2] + '.png',
          four: up[3] + '.png',
          five: up[4] + '.png',
          six: up[5] + '.png',
        },
        twoPrizes: {
          one: down[0] + '.png',
          two: down[1] + '.png',
          three: down[2] + '.png',
          four: down[3] + '.png',
          five: down[4] + '.png',
          six: down[5] + '.png',
        },
        oldAnnounce: true
      })
    }
  }

  afterAnnounce = async () => {
    const { newAnnounce } = this.state;
    if (!newAnnounce) {
      const statisticToday = await getStatisticToday();

      const up = statisticToday.data.up6;
      const down = statisticToday.data.down6;

      this.setState({
        onePrizes: {
          one: up[0] + '.png',
          two: up[1] + '.png',
          three: up[2] + '.png',
          four: up[3] + '.png',
          five: up[4] + '.png',
          six: up[5] + '.png',
        },
        twoPrizes: {
          one: down[0] + '.png',
          two: down[1] + '.png',
          three: down[2] + '.png',
          four: down[3] + '.png',
          five: down[4] + '.png',
          six: down[5] + '.png',
        },
        newAnnounce: true
      })
    }

  }

  setData = async () => {
    const { todayDate, beforeStartDown, beforeStartUp, downAnnounce, oldUpAnnounce, downRelease, upAnnounce } = this.state;

    // --------------------- *** DOWN *** -------------------------
    // before start down
    if (moment(todayDate).toDate().getTime() >= moment(beforeStartDownAnnounceDate).toDate().getTime() && moment(todayDate).toDate().getTime() <= moment(afterStartDownAnnounceDate).toDate().getTime()) {
      if (!beforeStartDown) {
        this.setState({
          onePrizes: {
            one: getLoading(),
            two: getLoading(),
            three: getLoading(),
            four: getLoading(),
            five: getLoading(),
            six: getLoading(),
          },
          twoPrizes: {
            one: getLoading(),
            two: getLoading(),
            three: getLoading(),
            four: getLoading(),
            five: getLoading(),
            six: getLoading(),
          },
          beforeStartDown: true
        })
      }
    }

    // down random number started
    if (moment(todayDate).toDate().getTime() >= moment(startDownAnnounceDate).toDate().getTime() && moment(todayDate).toDate().getTime() <= moment(endDownAnnouneDate).toDate().getTime()) {
      if (!this.downTimer) {
        this.downTimer = setInterval(() => {
          this.setState({
            twoPrizes: {
              one: randomNumber(),
              two: randomNumber(),
              three: randomNumber(),
              four: randomNumber(),
              five: randomNumber(),
              six: randomNumber(),
            }
          })
        }, 100);
      }
    }

    // down announce
    if (moment(todayDate).toDate().getTime() === moment(downAnnounceDate).toDate().getTime()) {
      if (this.downTimer) clearInterval(this.downTimer);
      // call api and show down
      if (!downAnnounce) {
        const dateNow = moment(todayDate).format("YYYY-MM-DD 16:30:00");
        const period = await getPeriods(dateNow, 'down', '2');
        const down = period.data.down6;

        this.setState({
          twoPrizes: {
            one: down[0] + '.png',
            two: down[1] + '.png',
            three: down[2] + '.png',
            four: down[3] + '.png',
            five: down[4] + '.png',
            six: down[5] + '.png',
          },
          downAnnounce: true
        });
      }
    }

    if (moment(todayDate).toDate().getTime() > moment(downAnnounceDate).toDate().getTime()) {
      if (this.downTimer) clearInterval(this.downTimer);
      // show release down
      if (!downRelease) {
        const dateNow = moment(todayDate).format("YYYY-MM-DD 16:30:00");
        const period = await getPeriods(dateNow, 'down', '2');
        const down = period.data.down6;

        this.setState({
          twoPrizes: {
            one: down[0] + '.png',
            two: down[1] + '.png',
            three: down[2] + '.png',
            four: down[3] + '.png',
            five: down[4] + '.png',
            six: down[5] + '.png',
          },
          downRelease: true
        });
      }
    }

    // --------------------- *** UP *** -------------------------
    if (moment(todayDate).toDate() < moment(startUpAnnounceDate).toDate()) {
      // show old up
      if (!oldUpAnnounce) {
        const dateNow = moment(todayDate).add(-1, 'd').format("YYYY-MM-DD 16:30:00");
        const period = await getPeriods(dateNow, 'up', '2');
        const up = period.data.up6;

        this.setState({
          onePrizes: {
            // one: up[0] + '.png',
            // two: up[1] + '.png',
            // three: up[2] + '.png',
            // four: up[3] + '.png',
            // five: up[4] + '.png',
            // six: up[5] + '.png',
            one: getLoading(),
            two: getLoading(),
            three: getLoading(),
            four: getLoading(),
            five: getLoading(),
            six: getLoading(),
          },
          oldUpAnnounce: true
        });
      }

    }

    // before start up
    if (moment(todayDate).toDate() >= moment(beforeStartUpAnnounceDate).toDate() && moment(todayDate).toDate() <= moment(afterStartUpAnnounceDate).toDate()) {
      if (!beforeStartUp) {
        this.setState({
          onePrizes: {
            one: getLoading(),
            two: getLoading(),
            three: getLoading(),
            four: getLoading(),
            five: getLoading(),
            six: getLoading(),
          },
          beforeStartUp: true
        })
      }
    }

    // up random number started
    if (moment(todayDate).toDate() >= moment(startUpAnnounceDate).toDate() && moment(todayDate).toDate() <= moment(endUpAnnouneDate).toDate()) {
      if (!this.upTimer) {
        this.upTimer = setInterval(() => {
          this.setState({
            onePrizes: {
              one: randomNumber(),
              two: randomNumber(),
              three: randomNumber(),
              four: randomNumber(),
              five: randomNumber(),
              six: randomNumber(),
            }
          })
        }, 100);
      }
    }

    // up announce
    if (moment(todayDate).toDate().getTime() === moment(upAnnounceDate).toDate().getTime()) {
      if (this.upTimer) clearInterval(this.upTimer);

      if (!upAnnounce) {
        // call api and show up 
        const statisticToday = await getStatisticToday();

        const up = statisticToday.data.up6;
        const down = statisticToday.data.down6;

        this.setState({
          onePrizes: {
            one: up[0] + '.png',
            two: up[1] + '.png',
            three: up[2] + '.png',
            four: up[3] + '.png',
            five: up[4] + '.png',
            six: up[5] + '.png',
          },
          twoPrizes: {
            one: down[0] + '.png',
            two: down[1] + '.png',
            three: down[2] + '.png',
            four: down[3] + '.png',
            five: down[4] + '.png',
            six: down[5] + '.png',
          },
          upAnnounce: true
        })
      }
    }
  }

  resizeWindow = () => {
    const width = window.innerWidth;
    if (width <= 500) {
      this.setState({ isMobile: true, lImage: 32, hImage: 65 });
    }
    else {
      this.setState({ isMobile: false, lImage: 70, hImage: '100%' });
    }
  }

  renderDate = () => {
    const { todayDate } = this.state;

    if (moment(todayDate).toDate().getTime() >= moment(beforeStartDownAnnounceDate).toDate().getTime()) {
      return moment().format("DD-MM-YYYY");
    }

    return moment().add(-1, 'd').format("DD-MM-YYYY");
  }

  render(): ReactNode {

    const { isMobile, lImage, onePrizes, twoPrizes, hImage } = this.state;

    return (
      <>
        <header>
          <div className="bg">
          </div>
        </header>

        <div className="container" style={{ padding: '20px 10px' }}>
          <div style={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
            <img
              style={{ marginRight: 2, width: 200, height: 75 }}
              src={require('./assets/images/date-announce.png')}
              alt='number'
            />
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              <h3 style={{ margin: 0 }}>{this.renderDate()}</h3>
            </div>
          </div>
          {/* <br /> */}
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <img
              style={{ marginRight: 2, width: 200, height: 75 }}
              src={require('./assets/images/time-announce.png')}
              alt='number'
            />
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              <h3 style={{ margin: 0 }}>{moment(new Date()).format("16:30")}</h3>
            </div>
          </div>

          <table className="table table-bordered" style={{ textAlign: 'center', width: isMobile ? '100%' : '50%', margin: '0 auto', borderColor: '#e45e0d' }}>
            <tbody>
              <tr>
                <td style={{ textAlign: 'center', padding: 0 }}>
                  <img
                    style={{ marginRight: 2, width: '100%', height: hImage }}
                    src={require('./assets/images/annup.png')}
                    alt='number'
                  />
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <img

                    src={require('./assets/images/' + onePrizes.one)}
                    width={lImage}
                    height={lImage}
                    alt='number'
                  />
                  <img

                    src={require('./assets/images/' + onePrizes.two)}
                    width={lImage}
                    height={lImage}
                    alt='number'
                  />
                  <img

                    src={require('./assets/images/' + onePrizes.three)}
                    width={lImage}
                    height={lImage}
                    alt='number'
                  />
                  <img

                    src={require('./assets/images/' + onePrizes.four)}
                    width={lImage}
                    height={lImage}
                    alt='number'
                  />
                  <img

                    src={require('./assets/images/' + onePrizes.five)}
                    width={lImage}
                    height={lImage}
                    alt='number'
                  />
                  <img
                    src={require('./assets/images/' + onePrizes.six)}
                    width={lImage}
                    height={lImage}
                    alt='number'
                  />
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: 'center', padding: 0 }}>
                  <img
                    style={{ marginRight: 2, width: '100%', height: hImage }}
                    src={require('./assets/images/anndown.png')}
                    alt='number'
                  />
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <img
                    src={require('./assets/images/' + twoPrizes.one)}
                    width={lImage}
                    height={lImage}
                    alt='number'
                  />
                  <img
                    src={require('./assets/images/' + twoPrizes.two)}
                    width={lImage}
                    height={lImage}
                    alt='number'
                  />
                  <img
                    src={require('./assets/images/' + twoPrizes.three)}
                    width={lImage}
                    height={lImage}
                    alt='number'
                  />
                  <img
                    src={require('./assets/images/' + twoPrizes.four)}
                    width={lImage}
                    height={lImage}
                    alt='number'
                  />
                  <img
                    src={require('./assets/images/' + twoPrizes.five)}
                    width={lImage}
                    height={lImage}
                    alt='number'
                  />
                  <img
                    src={require('./assets/images/' + twoPrizes.six)}
                    width={lImage}
                    height={lImage}
                    alt='number'
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <div style={{ textAlign: 'center' }}>
            <img
              style={{ marginRight: 2, width: 250, height: 90 }}
              src={require('./assets/images/statistic.png')}
              alt='number'
            />
          </div>
          <table className="table table-bordered" style={{ textAlign: 'center', width: isMobile ? '100%' : '50%', margin: '0 auto' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'center', padding: 0 }}>
                  <img
                    style={{ marginRight: 2, width: '100%' }}
                    src={require('./assets/images/03.png')}
                    alt='number'
                  />
                </th>
                <th style={{ textAlign: 'center', padding: 0 }}>
                  <img
                    style={{ marginRight: 2, width: '100%' }}
                    src={require('./assets/images/04.png')}
                    alt='number'
                  />
                </th>
                <th style={{ textAlign: 'center', padding: 0 }}>
                  <img
                    style={{ marginRight: 2, width: '100%' }}
                    src={require('./assets/images/05.png')}
                    alt='number'
                  />
                </th>
                <th style={{ textAlign: 'center', padding: 0 }}>
                  <img
                    style={{ marginRight: 2, width: '100%' }}
                    src={require('./assets/images/06.png')}
                    alt='number'
                  />
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: isMobile ? 12 : 14 }}>
              {
                this.state.statistics.map((v: any, index: number) => {
                  // if (index !== 0 && index < 10) {
                  return <tr key={index + v.dateAnnounce}>
                    <th>{moment(v.dateAnnounce).format("DD-MM-") + moment(v.dateAnnounce).add(543, 'years').format("YYYY")}</th>
                    <td>{v.up3}</td>
                    <td>{v.up2}</td>
                    <td>{v.down2}</td>
                  </tr>
                  // }
                })
              }
            </tbody>
          </table>
        </div>
      </>
    );
  }

}

export default App;
